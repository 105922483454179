type LoadingSpinnerProps = {
  size?: string;
};

export function LoadingSpinner({ size = "24" }: LoadingSpinnerProps) {
  return (
    <div
      className={`w-[${size}px] h-[${size}px] animate-spin rounded-full border-4 border-b-gray-500`}
    />
  );
}
